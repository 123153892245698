<template>
  <div class="basic_container_noflex">
    <div>
      <h1>公司简介</h1>
      <p style="text-align: left" v-for="(item,index) in this.contents" :key="index">{{item}}</p>
<!--        宜兴市华恒高性能纤维织造有限公司成立于2001年，是一家从事高性能纤维织造、研制、开发于一体的科技型生产企业。公司主要以碳纤维、芳纶纤维、玻璃纤维、聚乙烯纤维、玄武岩纤维、维尼纶纤维、聚肪脂纤维、PBO纤维、石英纤维布、芳砜纶布等高分子材料为原料进行织造复合。</p>-->
<!--      <p style="text-align: left">-->
<!--        公司主要产品有：碳纤维编织物、多轴向布、混编布、芳纶布、聚乙烯布、聚丙烯晴预氧布、石英纤维布、芳砜纶布、短切碳纤维、短切芳纶纤维、碳纤维板、大丝束碳纤维、碳粉、碳粒子等。主要应用于航空航天领域，还面向广阔的民用市场如：光伏用C/C碳纤维预制体、风电行业用织物和预浸料、汽与轨道交通轻量化、汽车齿轮箱配件、压力容器、船体、运动器材、纺织器材、音盆配件、摩擦和密封材料等</p>-->
<!--      <p style="text-align: left"> 公司将继续坚持，以质取胜、以信致远、开拓求实、团结进取的宗旨与精神为社会各界朋友提供良好的产品和服务，为我国复合材料事业作出贡献。</p>-->
    </div>
    <div style="display: flex;flex-direction: row;justify-content: center;margin: 50px 0">
      <el-image style="margin-left: 20px;height: 300px;width:450px" v-for="(item,i) in this.images" :key="i" :src="item"></el-image>
    </div>

  </div>
</template>

<script>
import {httpAction} from "@/api/manage";

export default {
  name: "CompanyProfile",

  data() {
    return {
      urls: {
        companyInfo: "/companyManage/getCompanyInfo"
      },
      images: [],
      contents: [],
    }
  },
  created() {
    this.getContext()
  },
  methods: {
    getContext() {
      httpAction(this.urls.companyInfo, {body: {itemValue: "companyOver"}}).then(res => {
        if (res.code == 0) {
          this.contents = res.data.companyOver.split("<p>")
        }
        return httpAction(this.urls.companyInfo, {body: {itemValue: "companyOverImage"}});
      }).then(res1 => {
        if (res1.code == 0) {
          if (res1.data.companyOverImage) {
            this.images = res1.data.companyOverImage.indexOf(",") == -1 ? [res1.data.companyOverImage] : res1.data.companyOverImage.split(",")
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.basic_container_noflex {
  width: 1080px;
  position: relative;
  margin: 10px auto;
}

</style>
